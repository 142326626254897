'use strict';

var globalModalCopy = $(document).find('.globalModal-copy').html();

var productId = null;
var productName = null;
var productImage = null;
var categoryId = null;
var productAvailbilityDate = null;
var actionUrl = '';
var containertype = '';
/** Subscriber modal data */
function fillModal() {
    $(document).find('.globalModal .modal-body').empty();
    $(document).find('.globalModal .modal-body').append(globalModalCopy);
}
/** Share wishlist modal data */
function fillWishlistModal() {
    var wishlistModalCopy = $(document).find('.shareWishlist-copy').html();
    $(document).find('#shareWishlistModal .modal-body').empty();
    $(document).find('#shareWishlistModal .modal-body').append(wishlistModalCopy);
}

/** Content slot change based on container type */
function contentSlotChange() {
    if (containertype != null && containertype === 'out-of-stock-notify') {
        $('.content-rewards-signup').hide();
        $('.content-rewards-notavailable').hide();
        $('.content-rewards-outofstock').show();
    } else if (containertype != null && containertype === 'not-available-yet') {
        $('.content-rewards-signup').hide();
        $('.content-rewards-notavailable').show();
        $('.content-rewards-outofstock').hide();
    } else {
        $('.content-rewards-signup').show();
        $('.content-rewards-notavailable').hide();
        $('.content-rewards-outofstock').hide();
    }

    if (containertype != null && containertype === 'pgptile') {
        $('#subscribe-modal-wrapper .privacy-policy').hide();
        $('#subscribe-modal-wrapper .below-cta-text').show();
    } else {
        $('#subscribe-modal-wrapper .below-cta-text').hide();
        $('#subscribe-modal-wrapper .privacy-policy').show();
    }
}

$(document).on('click', 'button.subscribe', function (e) {
    e.preventDefault();
    var $button = $(this);
    var $subscribeModalWrapper = $('#subscribe-modal-wrapper');
    var url = $subscribeModalWrapper.data('url');
    if (!url) {
        return;
    }
    var isIPhone = !!navigator.userAgent.match(/iPhone/i);

    $subscribeModalWrapper.load(url, function () {
        $('#subscribe-global-modal').modal('show');

        if (isIPhone) {
            $('#subscribe-global-modal').addClass('iPhone');
        }

        containertype = $button.data('containertype');
        if (containertype) {
            if (containertype === 'pgptile') {
                var emailsource = $button.data('emailsource');
                var $emainSourceElement = $('.subscribe-email-source');
                if ($emainSourceElement && $emainSourceElement.length && emailsource) {
                    $emainSourceElement.val(emailsource);
                }
                var $headerElement = $('.subscribe-title').first().children(':first');
                if ($headerElement && $headerElement.length) {
                    $headerElement.text($button.data('header'));
                }
                var $subHeaderElement = $headerElement.next();
                if ($subHeaderElement && $subHeaderElement.length) {
                    $subHeaderElement.html('').append($button.data('subheader'));
                }

                $subscribeModalWrapper.find('.below-cta-text').text($button.data('belowctatext'));
            } else if (containertype === 'out-of-stock-notify' || containertype === 'not-available-yet') {
                productId = $button.attr('data-pid');
                productName = $button.data('pname');
                productImage = $button.data('pimage');
                categoryId = $button.data('cgid');
                productAvailbilityDate = $button.data('productavailbilitydate');
                actionUrl = $button.data('actionurl');
            }
        }
        contentSlotChange();
    });
});

$(document).on('click', '#share-wishlist-link', function (e) {
    e.preventDefault();
    fillWishlistModal();

    setTimeout(function () {
        $(document).find('.modal input').first().focus();
    }, 1000);
});

/** on window load Subscriber modal data to be filled */
function globalModal() {
    setTimeout(function () {
        fillModal();
    }, 5000);
}
globalModal();

/**
 * This will hide/show modal elements on signup/notifyme modal success.
 */
function uiHandlingOnGlobalModalSuccess() {
    $('.thanks-msg').show();
    $('.subscribe-btn').hide();
    $('.subscribe-title').hide();
    $(document).find('.subscribed').addClass('show');
    $(document).find('input.subscriber-email').prop('disabled', true);
}

/**
 * Function to hide/show modal elements on email unsubscribe modal success.
 * @param {string} msg - Message to show as title
 */
function uiHandlingUnSubscribeSuccess(msg) {
    $('.thanks-msg').show().find('div').html(msg);
    $('.unsubscribe-btn').hide();
    $('.subscribe-title').hide();
    $('#subscribe-modal-wrapper .below-cta-text').hide();
    $('.unsubscribe-success-message').removeClass('d-none');
    $(document).find('input.subscriber-email').prop('disabled', true);
}

$(document).on('click', '#emailunsubscribe', function () {
    $(this).hide();
    $('.subscribe-btn, .privacy-policy').hide();
    $('.unsubscribe-btn').removeClass('d-none');
    $('.subscribe-title div').first().html($(this).data('title'));
    $('.subscribe-title div:last-child').html($(this).data('subtitle'));
});

$(document).on('click', '.subscribe-btn', function (e) {
    e.preventDefault();
    var emailReg = /^[\w.%+-]+@[\w.-]+\.[\w]{2,6}$/;
    var emailSource = $(document).find('input.subscribe-email-source').val();
    var emailaddressVal = $(document).find('input.subscriber-email').val();
    if (emailaddressVal === '') {
        $('.invalid-email').empty();
        $('.invalid-email').show();
        $('.subscribed').hide();
        var emptyMsg = $(document).find('input.subscriber-email').data('msg-empty');
        $(document).find('.invalid-email').append('<span class="error">' + emptyMsg + '</span>');
    } else if (!emailReg.test(emailaddressVal)) {
        $('.invalid-email').empty();
        $('.subscribed').hide();
        var invalidMsg = $(document).find('input.subscriber-email').data('msg-invalid');
        $(document).find('.invalid-email').show().append('<span class="error">' + invalidMsg + '</span>');
    } else {
        $(document).find('.invalid-email').hide();
        if (containertype != null && (containertype === 'out-of-stock-notify' || containertype === 'not-available-yet')) {
            $.ajax({
                url: actionUrl,
                type: 'post',
                dataType: 'json',
                data: {
                    pid: productId,
                    productName: productName,
                    productImage: productImage,
                    categoryId: categoryId,
                    email: emailaddressVal,
                    productAvailbilityDate: productAvailbilityDate
                },
                success: function (data) {
                    if (!data.success) {
                        $(document).find('.invalid-email').show().append('<span class="error">' + data.msg + '</span>');
                    } else {
                        uiHandlingOnGlobalModalSuccess();
                    }
                },
                error: function () {
                    $('.modal-header').find('button.close').trigger();
                }
            });
        } else {
            var url = $(this).closest('form.subscribe-form').attr('action');
            $.ajax({
                url: url,
                type: 'post',
                dataType: 'json',
                data: {
                    email: emailaddressVal,
                    emailSource: emailSource
                },
                success: function (data) {
                    if (!data.success) {
                        $(document).find('.invalid-email').show().append('<span class="error">' + data.msg + '</span>');
                    } else {
                        uiHandlingOnGlobalModalSuccess();
                        $('#emailunsubscribe').hide();
                    }
                },
                error: function () {
                    $('.modal-header').find('button.close').trigger();
                }
            });
        }
    }
});

$(document).on('click', '.unsubscribe-btn', function (e) {
    e.preventDefault();
    var emailReg = /^[\w.%+-]+@[\w.-]+\.[\w]{2,6}$/;
    var emailaddressVal = $(document).find('input.subscriber-email').val();
    if (emailaddressVal === '') {
        $('.invalid-email').empty();
        $('.invalid-email').show();
        $('.subscribed').hide();
        var emptyMsg = $(document).find('input.subscriber-email').data('msg-empty');
        $(document).find('.invalid-email').append('<span class="error">' + emptyMsg + '</span>');
    } else if (!emailReg.test(emailaddressVal)) {
        $('.invalid-email').empty();
        $('.subscribed').hide();
        var invalidMsg = $(document).find('input.subscriber-email').data('msg-invalid');
        $(document).find('.invalid-email').show().append('<span class="error">' + invalidMsg + '</span>');
    } else {
        $(document).find('.invalid-email').hide();
        var url = $(this).closest('form.subscribe-form').attr('action');
        var emailSource = $(document).find('input.subscribe-email-source').val();
        $.ajax({
            url: url,
            type: 'post',
            dataType: 'json',
            data: {
                email: emailaddressVal,
                emailSource: emailSource,
                optout: true
            },
            success: function (data) {
                if (!data.success) {
                    $(document).find('.invalid-email').show().append('<span class="error">' + data.msg + '</span>');
                } else {
                    uiHandlingUnSubscribeSuccess(data.msg);
                }
            },
            error: function () {
                $('.modal-header').find('button.close').trigger();
            }
        });
    }
});

$('body').on('click', '.footer-columns button', function () {
    $(this).find('span').toggleClass('d-none');
});

/**
 * Trigger form when subscribe modal is closed.
 */
$(document).on('hide.bs.modal', '#subscribe-global-modal', function () {
    $('.subscribe-email-source').val('');
    $('.subscribe-form').trigger('reset');
});

/*
Commented as part of Story SFRA-1209
$(window).scroll(function () {
    if ($(this).scrollTop() >= 300) {
        $('body').find('.backtotop').show();
    } else {
        $('body').find('.backtotop').hide();
    }
});

$(document).on('click', '.backtotop .goback', function (e) {
    e.preventDefault();
    $('html, body').animate({ scrollTop: 0 }, '300');
});
*/
