'use strict';

/**
 * Decode a URI component in a safe way.
 * @param  {string} value - value
 * @returns {string} - Decoded value.
 */
function safeDecodeUriComponent(value) {
    try {
        return decodeURIComponent(value);
    } catch (e) {
        return '';
    }
}

/**
 * Get search params from url.
 * @param {string} url - URL.
 * @returns {Object} - URL paarams.
 */
function getSearchParams(url) {
    if (typeof url !== 'string') {
        return {};
    }

    const queryMatch = url.match(/\?([^#]+)/);

    if (!queryMatch) {
        return {};
    }

    return queryMatch[1].split('&').reduce(function (result, part) {
        const keyValue = part.split('=');
        const key = safeDecodeUriComponent(keyValue[0]);
        const value = safeDecodeUriComponent(keyValue[1] || '');

        if (key) {
            // eslint-disable-next-line no-param-reassign
            result[key] = value || '';
        }

        return result;
    }, {});
}

module.exports = {
    getSearchParams: getSearchParams,
    safeDecodeUriComponent: safeDecodeUriComponent
};
