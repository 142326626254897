'use strict';

var copyFrom = ['account-header'];

/**
 * Clone element to target locations.
 * @param {HTMLElement} from - Element to clone.
 * @param {NodeList} to - Target elements.
 */
function clone(from, to) {
    for (var i = 0; i < to.length; i += 1) {
        var target = to[i];

        var newElement = from.cloneNode(true);
        newElement.setAttribute('data-copied-from', from.getAttribute('data-copy-from'));
        newElement.removeAttribute('data-copy-from');

        target.parentNode.replaceChild(newElement, target);
    }
}

/**
 * Copy elements.
 */
function copyElements() {
    for (var i = 0; i < copyFrom.length; i += 1) {
        try {
            var type = copyFrom[i];
            var from = document.querySelector('[data-copy-from="' + type + '"]');

            if (from) {
                var to = document.querySelectorAll('[data-copy-to="' + type + '"]');
                clone(from, to);
            }
        } catch (e) {
            window.console.error(e);
        }
    }
}

module.exports = {
    copyElements
};
