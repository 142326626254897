'use strict';

/**
 * Open modal window
 * @param {Event} e - event
 */
function openAfterpayModal(e) {
    e.preventDefault();

    var url = $(this).prop('href');
    $.get(url, function (data) {
        $('#afterpay-tooltip-show .modal-body').empty().append(data);
    });
}

/**
 * Open afterPay modal window
 */
function afterPayMobileTooltip() {
    if ($('#afterpay-tooltip-show').length !== 0) {
        $('#afterpay-tooltip-show').remove();
    }
    var htmlString = '<!-- Modal -->'
    + '<div class="modal fade" id="afterpay-tooltip-show" tabindex="-1" role="dialog">'
    + '<span class="enter-message sr-only" ></span>'
    + '<div class="modal-dialog">'
    + '<!-- Modal content-->'
    + '<div class="modal-content">'
    + '<div class="modal-header">'
    + '    <button type="button" class="close pull-right" data-dismiss="modal" aria-label="close dialog">'
    + '        <span class="pr-2 close-button" aria-hidden="true">&times;</span>'
    + '    </button>'
    + '</div>'
    + '<div class="modal-body"></div>'
    + '</div>'
    + '</div>'
    + '</div>';
    $('body').append(htmlString);
} afterPayMobileTooltip();

var afterpay = {
    init: function () {
        $(document).on('click', '.afterpay-mob-info', openAfterpayModal);

        $('body').on('click', '#afterpayModal .modal-header button', function () {
            $('#afterpayModal').remove();
            $('body').removeClass('modal-open');
            $('.modal-background').hide();
            $('.modal-background').removeClass('offset-top-menu');
        });

        $(document).on('keydown', function (e) {
            if (e.keyCode === 27) {
                $('#afterpayModal').remove();
                $('body').removeClass('modal-open');
            }
        });

        $('body').on('click', function (e) {
            if (!$('.afterpayModal').has(e.target).length && $('#afterpayModal').length) {
                $('#afterpayModal').remove();
                $('body').removeClass('modal-open');
                $('.modal-background').removeClass('offset-top-menu');
            }
        });
    }
};

module.exports = afterpay;
