/* eslint-disable sonarjs/no-duplicate-string */
/* eslint no-unused-expressions: ["error", { "allowShortCircuit": true, "allowTernary": true }] */

'use strict';

const eyeIcon = document.getElementById('eye-inactive-grey').cloneNode(true).outerHTML;
const eyeActiveIcon = document.getElementById('eye-active').cloneNode(true).outerHTML;

class TextInputComponent extends HTMLElement {
    static init() {
        if (!window.customElements || window.customElements.get('text-input-component')) {
            return;
        }

        window.customElements.define('text-input-component', TextInputComponent);
    }

    static get observedAttributes() {
        return ['auto-disable-button', 'button-text'];
    }

    constructor() {
        super();
        this.attachShadow({ mode: 'open' });
        this.slottedInput = this.querySelector('input');
        this.buttonElement = null;
        this.isRequiredInput = !!this.querySelector('[required]');

        const template = document.createElement('template');
        template.innerHTML = this.getTemplate();
        this.shadowRoot.appendChild(template.content.cloneNode(true));

        this.createButton();
    }

    getTemplate() {
        return `
            <style>${this.getStyles()}</style>
            <slot></slot>
        `;
    }

    // eslint-disable-next-line class-methods-use-this
    getStyles() {
        return `
            :host {
                display: block;
                height: 58px;
                font-family: Proxima Nova W01, -apple-system, BlinkMacSystemFont, Helvetica Neue, Helvetica, Arial, sans-serif; 
                position: relative;
                margin-bottom: 20px;
                opacity: 1;
                transition: 0.2s;
            }

            ::slotted(label) {
                position: absolute !important;
                top: 12px !important;
                left: 1rem !important;
                line-height: 1.2 !important;
                pointer-events: none !important;
                font-size: 14px !important;
                font-weight: 300 !important;
                color: #999 !important;
                margin: 0 !important;
            }

            :host(.required) ::slotted(label)::after {
                content: '*';
                color: #999;
                margin-left: 2px !important;
            }

            ::slotted(input) {
                display: block !important;
                font-weight: var(--nb-font-weight-05) !important;
                font-size: var(--nb-font-size-04) !important;
                color: black !important;
                padding-top: 2rem !important;
                padding-bottom: 12px !important;
                padding-left: 1rem !important;
                padding-right: 1rem;
                height: 100% !important;
                width: 100% !important;
                border: 1px solid #abb0b4 !important;
                border-radius: 4px !important;
                background-color: white !important;
                box-sizing: border-box !important;
                transition: 0.15s ease-in-out 0s !important;
            }

            ::slotted(input.is-invalid),
            :host(.is-invalid) ::slotted(input) {
                border: 1px solid var(--nb-color-primary) !important;
            }
                
            ::slotted(input:focus) {
                border-color: #151415 !important;
                outline: none !important;
            }
            ::slotted(.invalid-feedback) {
                margin-top: 0.25rem !important;
            }

            button {
                position: absolute;
                display: flex;
                align-items: center;
                height: 32px;
                padding: 8px;
                font-weight: 500;
                font-family: Proxima Nova W01, -apple-system, BlinkMacSystemFont, Helvetica Neue, Helvetica, Arial, sans-serif; 
                background-color: var(--nb-color-black);
                color: var(--nb-color-white);
                border: none;
                border-radius: 4px;
                top: 50%;
                right: 12px;
                cursor: pointer;
                transform: translate(0%, -50%);
                transition: 0.15s;
            }

            button[disabled] {
                color: #ABB0B4;
                background-color: #F2F2F3;
                pointer-events: none;
            }

            button.password-toggle {
                width: 24px;
                height: 24px;
                padding: 0;
                cursor: pointer;
                background-color: transparent;
            }
        `;
    }

    connectedCallback() {
        this.setupEventListeners();
        this.isRequiredInput && this.classList.add('required');
        this.hasAttribute('auto-disable-button') && this.checkInputValue();
        if (this.slottedInput && this.slottedInput.type === 'password') {
            this.createPasswordToggleButton();
        }
    }

    attributeChangedCallback(name, oldValue, newValue) {
        if (name === 'auto-disable-button') {
            this.checkInputValue();
        }
        if (name === 'button-text') {
            if (this.buttonElement === null) {
                this.createButton();
            } else {
                this.buttonElement.innerText = newValue;
            }
        }
    }

    createButton() {
        const buttonText = this.getAttribute('button-text');
        if (buttonText) {
            this.buttonElement = document.createElement('button');
            this.buttonElement.innerText = buttonText;
            this.shadowRoot.appendChild(this.buttonElement);
        }
    }

    setupEventListeners() {
        if (this.hasAttribute('auto-disable-button')) {
            this.slottedInput.addEventListener('input', () => this.checkInputValue());
        }

        if (this.buttonElement) {
            this.buttonElement.addEventListener('click', () => this.handleSubmit());
            this.slottedInput.style.paddingRight = `${this.buttonElement.offsetWidth + 14}px`;
        }
    }

    handleSubmit() {
        const parentForm = this.closest('form');
        if (parentForm) {
            parentForm.requestSubmit();
        }
    }

    createPasswordToggleButton() {
        this.toggleButton = document.createElement('button');
        this.toggleButton.classList.add('password-toggle');
        this.toggleButton.setAttribute('type', 'button');
        this.toggleButton.setAttribute('aria-label', 'Show password');
        this.toggleButton.innerHTML = eyeIcon;

        this.toggleButton.addEventListener('click', () => this.togglePasswordVisibility());
        this.shadowRoot.appendChild(this.toggleButton);
    }

    togglePasswordVisibility() {
        const isPassword = this.slottedInput.type === 'password';
        this.toggleButton.innerHTML = isPassword ? eyeActiveIcon : eyeIcon;
        this.slottedInput.type = isPassword ? 'text' : 'password';
        this.toggleButton.setAttribute('aria-label', isPassword ? 'Hide password' : 'Show password');
    }

    checkInputValue() {
        if (this.slottedInput && this.buttonElement) {
            this.slottedInput.value
                ? this.buttonElement.removeAttribute('disabled')
                : this.buttonElement.setAttribute('disabled', '');
        }
    }
}

module.exports = TextInputComponent;
