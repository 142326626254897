'use strict';

const events = require('../util/events');

/**
* Handler for click event of order-status, order-return and faq links from chat modal.
* This function is added into this file because these links are common for ZenDesk and Service Cloud Chat modal,
* Instead of duplicating the code into individual files.
*/
function initTealiumChatModalLinksClickHandler() {
    $('#emeaHelpModal .js-link-order-return, #emeaHelpModal .js-link-order-status, #emeaHelpModal .js-link-faq').on('click', function (e) {
        e.preventDefault();
        var $currentLink = $(e.target);
        /**
         * Callback function to be executed after tealium event is fired.
         */
        var redirectToLink = function () {
            if ($currentLink && $currentLink.attr('href') !== null) {
                window.location = $currentLink.attr('href');
            }
        };

        if ($currentLink.hasClass('js-link-order-return')) {
            $(document).trigger('tealium:ChatOrderReturnLink', redirectToLink);
        } else if ($currentLink.hasClass('js-link-order-status')) {
            $(document).trigger('tealium:ChatOrderStatusLink', redirectToLink);
        } else if ($currentLink.hasClass('js-link-faq')) {
            $(document).trigger('tealium:ChatFAQLink', redirectToLink);
        }
    });
}

module.exports = {

    init() {
        document.addEventListener('click', (event) => {
            events.triggerEventDelayed(event.target, events.TRACKING_GLOBAL);
        }, true);
    },
    initTealiumChatModalLinksClickHandler: initTealiumChatModalLinksClickHandler

};
