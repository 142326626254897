'use strict';

var caretDefaultPosition = false;
/**
 * Removes active class on the ancestor div.form-group-select
 * @param {event} event of the select that was clicked on
 */
function removeActive(event) {
    var target = event.target.closest('div.form-group-select');
    if (target) target.classList.remove('active');

    if (event.type === 'change') {
        caretDefaultPosition = true;
    } else {
        caretDefaultPosition = false;
    }
}

/**
 * Adds active class on the ancestor div.form-group-select
 * @param {event} event of the select that was clicked on
 */
function makeActive(event) {
    var target = event.target.closest('div.form-group-select');
    if (target && !caretDefaultPosition) target.classList.add('active');
    if (caretDefaultPosition) {
        caretDefaultPosition = false;
    }
}

/**
 * Initiates all select elements with .custom-select
 * Add class of form-group-select to the ancestor div.form-group
 * Attach event listeners for all select.custom-select
 */
function initSelectElements() {
    var selectElements = document.getElementsByClassName('custom-select');
    if (selectElements && selectElements.length) {
        Array.from(selectElements).forEach(function (selectElement) {
            if (selectElement.options && selectElement.options.length) {
                selectElement.options[0].classList.add('font-weight-semibold');
            }
            if (selectElement.closest('.form-group') !== null) {
                selectElement.closest('.form-group').classList.add('form-group-select');
                selectElement.addEventListener('click', makeActive);
                selectElement.addEventListener('blur', removeActive);
                selectElement.addEventListener('change', removeActive);
                selectElement.addEventListener('select', removeActive);
            }
        });
    }
}

initSelectElements();

window.initSelectElements = initSelectElements;
