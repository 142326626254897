'use strict';

/**
 * Converts a shipping object into an address object + template
 * @param {Object} shipping The shipping object
 * @returns {Object} the address object and template
 */
function createAddressObj(shipping) {
    var address = shipping.shippingAddress || {};
    var selectedMethod = shipping.selectedShippingMethod;

    address.nameLine = address.firstName ? address.firstName + ' ' : '';
    if (address.lastName) address.nameLine += address.lastName;

    address.shippingCost = selectedMethod ? selectedMethod.shippingCost : '';
    address.methodNameLine = selectedMethod ? selectedMethod.displayName : '';
    address.methodArrivalTime = selectedMethod && selectedMethod.estimatedArrivalTime
        ? '(' + selectedMethod.estimatedArrivalTime + ')'
        : '';

    var tmpl = $('#pli-shipping-summary-template').clone();
    return {
        address: address,
        tmpl: tmpl
    };
}

/**
 * Converts a shipping object into an address object + template
 * @param {Object} shipping The shipping object
 * @param {Object} address The address object
 * @param {Object} tmpl address object template
 */
function fillShippingSummary(shipping, address, tmpl) {
    $('.ship-to-name', tmpl).text(address.nameLine);
    if (shipping.shippingAddress !== null) {
        $('.ship-to-address1', tmpl).text(shipping.shippingAddress.address1);
        $('.ship-to-address2', tmpl).text(shipping.shippingAddress.address2);
        $('.ship-to-address3', tmpl).text(shipping.shippingAddress.address3);
        $('.ship-to-city', tmpl).text(shipping.shippingAddress.city);

        if (!shipping.shippingAddress.address2) {
            $('.ship-to-address2', tmpl).hide();
        }
        if (!shipping.shippingAddress.address3) {
            $('.ship-to-address3', tmpl).hide();
        }
        if (!shipping.shippingAddress.phone) {
            $('.ship-to-phone', tmpl).hide();
        }
    }
    if (shipping.shippingAddress != null) {
        $('.ship-to-st', tmpl).text(shipping.shippingAddress.stateCode);
    }
    $('.ship-to-zip', tmpl).text(address.postalCode);
    $('.ship-to-phone', tmpl).text(address.phone);

    if (shipping.selectedShippingMethod) {
        $('.display-name', tmpl).text(address.methodNameLine);
        $('.arrival-time', tmpl).text(address.methodArrivalTime);
        $('.price', tmpl).text(address.shippingCost);
    }
}

/**
 * Triggers the tealium error event for respective field.
 * @param {element} formElement - field to used to fire error event
 * @returns {void}
 */
function tealiumErrorTracking(formElement) {
    var $formField = $(formElement);
    var $form = $(formElement.form);
    var $document = $(document);
    var gaLabel = $formField[0].placeholder || $formField[0].labels[0].innerText.trim();
    gaLabel = formElement.type === 'tel' ? $formField[0].labels[0].innerText.trim() : gaLabel;

    var gaAction;

    if ($form.hasClass('billing-information') && $formField.closest('.row').parent().hasClass('add-card-form')) {
        gaAction = 'Credit Card Error - Form';
        $document.trigger('credit-card:error', { gaAction: gaAction, gaLabel: gaLabel });
    } else if ($form.hasClass('gift-card-form')) {
        gaAction = 'Gift Card Error - Form';
        $document.trigger('gift-card:error', { gaAction: gaAction, gaLabel: gaLabel });
    }
}

module.exports = {
    createAddressObj: createAddressObj,
    fillShippingSummary: fillShippingSummary,
    tealiumErrorTracking: tealiumErrorTracking
};
