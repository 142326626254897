'use strict';

var currentFilePath;
if (document.currentScript) {
    currentFilePath = document.currentScript.src;
} else {
    var scripts = document.getElementsByTagName('script');
    currentFilePath = scripts[scripts.length - 1].src;
}
// eslint-disable-next-line no-undef, camelcase
__webpack_public_path__ = currentFilePath.substring(0, currentFilePath.lastIndexOf('/')) + '/'; // NOSONAR
