'use strict';

class VerticalProductCarousel extends HTMLElement {
    static init() {
        if (!window.customElements || window.customElements.get('vertical-product-carousel')) {
            return;
        }

        window.customElements.define('vertical-product-carousel', VerticalProductCarousel);
    }

    constructor() {
        super();

        this.currentWindowWidth = window.innerWidth;
    }

    // Lifecycle methods.

    connectedCallback() {
        this.init();

        window.addEventListener('resize', this, false);
    }

    disconnectedCallback() {
        window.removeEventListener('resize', this, false);
    }

    // EventListener implementation.

    handleEvent(e) {
        switch (e.type) {
            case 'resize': {
                const currentWidth = window.innerWidth;
                if (this.currentWindowWidth !== currentWidth) {
                    this.currentWindowWidth = currentWidth;
                    this.init();
                }
                break;
            }
            default: {
                // do nothing
                break;
            }
        }
    }

    // Init.

    init() {
        const $verticalCarousel = $(this);
        const $carousel = $verticalCarousel.find('.carousel').first();
        const textContainer = $verticalCarousel.find('.vertical-text-container');

        if ($carousel.hasClass('slick-initialized')) {
            $carousel.slick('unslick');
        }

        if (this.currentWindowWidth < 992) {
            textContainer.removeAttr('style');
            $verticalCarousel.removeAttr('style');
            import(/* webpackChunkName: "slick" */ '@accessible360/accessible-slick/slick/slick.min.js').then(function () {
                $carousel.slick({
                    slidesToShow: 1.265,
                    slidesToScroll: 1,
                    infinite: false,
                    vertical: false,
                    arrows: false,
                    dots: true
                });
            });
        } else {
            const tiles = $carousel.find('.p-tile');
            const headerheight = $('.header-fixed').height();
            $verticalCarousel.removeClass('invisible');

            if (tiles.length > 1) {
                textContainer.css({
                    top: headerheight,
                    height: 'calc(100vh - ' + headerheight + 'px)'
                });
            }
        }
    }
}

module.exports = VerticalProductCarousel;
