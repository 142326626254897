/* global StyliticsGalleryWidget,StyliticsDimensionCollageWidget, StyliticsGalleryWidget, StyliticsClassicWidget, StyliticsDynamicGalleriesWidget */

'use strict';

var utils = require('../utils');

// constants
var $productDetail = $('.product-detail');
var shopTheLook = '.shop-the-look';

/**
 * Initializes stylitics jump link
 */
function initializeJumpLink() {
    var $shopTheLook = $(shopTheLook);
    $('.stylitics-jump-link').on('click', function (e) {
        e.preventDefault();
        var offset = (utils.isMobile() && $('.header-fixed').height() !== undefined) ? 250 : 0;

        $('html, body').animate({
            scrollTop: $shopTheLook.offset().top - offset
        }, 500);

        if (window.utag_data) {
            var pid = $productDetail.data('masterid');
            $(document).trigger('stylitics:jumplink', pid);
        }
    });
}

/**
 * Gets active style
 * @returns {string} currently selected style number
 */
function getActiveStyle() {
    return $('.style-attribute.selected').attr('data-attrvalue') !== undefined ? $('.style-attribute.selected').attr('data-attrvalue') : $('.cart-page .product-info').data('style');
}

/**
 * Get Customer Property for the PDP Stylitics Widget
 * @param {Element} container - container on which the widget should be initialized
 * @returns {Object} customerProp - returns customer id from tealium's utag_data and the locale data from the html container
 */
function getCustomerProperty(container) {
    var customer = {
        locale: $(container).data('locale').replace('_', '-')
    };

    // If user is not authenticated, customerId will be null
    if (window.uncachedData && window.uncachedData.user && window.uncachedData.user.customerId) {
        customer.profileId = window.uncachedData.user.customerId;
    }

    return customer;
}

/**
 * Method to get data related to stylitics dynamic gallery widget
 * @param {*} container placeholder for container selector value
 * @returns {Object} object holding data related to stylitics dynamic gallery widget
 */
function getStyliticsWidgetContainerData(container) {
    var styliticsWidgetContainerData = {};
    var $styliticsContainer = $(container);
    if ($styliticsContainer && $styliticsContainer.length) {
        styliticsWidgetContainerData.api = {};
        var apiPage = $styliticsContainer.attr('data-api-page');
        if (apiPage && apiPage.trim() !== '') {
            styliticsWidgetContainerData.api.page = apiPage;
        }

        styliticsWidgetContainerData.customer = getCustomerProperty(container);
    }
    return styliticsWidgetContainerData;
}

/**
 * Create Shop the Look - Dynamic Galleries Component
 * @param {Element} container - container on which the widget should be initialized
 */
function createShopTheLookDynamicGalleries(container) {
    var userName = $(container).data('username');
    var containerId = $(container).data('componentId');
    var styliticsWidgetContainer = 'stylitics-dynamic-gallery-' + containerId;
    var styliticsWidgetContainerData = getStyliticsWidgetContainerData(container);
    var $styliticsWidgetContainer = $('#' + styliticsWidgetContainer);

    if ($styliticsWidgetContainer.data('initialized') !== true && styliticsWidgetContainer && styliticsWidgetContainerData && userName) {
        $styliticsWidgetContainer.data('initialized', true);
        let widgetInstance = new StyliticsDynamicGalleriesWidget(userName, styliticsWidgetContainer, {
            ...styliticsWidgetContainerData,
            responsive: [
                [0, { columns: 1 }],
                [768, { columns: 3 }]
            ],
            display: {
                ...styliticsWidgetContainerData.display,
                cardAspectRatio: 1.5
            }
        });

        widgetInstance.on('mount', 'bundles', function (data) {
            var $headline = $('#stylitics-container-dynamic-gallery-' + containerId + ' h2');
            if (data.bundles && data.bundles.length >= 3) {
                $headline.removeClass('d-none');
            }
        });

        widgetInstance.start();
    }
}

/**
 * Create PDP Stylitics Widget -- shop the look section; currently displaying the StyliticsDimensionsCollage
 * @param {Element} container - container on which the widget should be initialized
 */
function createStyliticsPDPCarousel(container) {
    var styliticsContainerId = $(container).data('componentId');
    var $styliticsContainer = $('#stylitics-container-' + styliticsContainerId);
    var $styliticsWidgetContainer = $styliticsContainer.find('[id^=stylitics-widget-container-]');
    var itemNumber = $(container).data('itemNumber') !== '' ? $(container).data('itemNumber') : getActiveStyle();
    var customerProp = getCustomerProperty(container);

    if ($styliticsContainer.data('initialized') !== true && $styliticsWidgetContainer.is(':empty') && typeof StyliticsDimensionCollageWidget !== 'undefined') {
        $styliticsContainer.data('initialized', true);

        let widgetInstance = new StyliticsDimensionCollageWidget($(container).data('username'), $styliticsWidgetContainer.attr('id'), {
            api: {
                item_number: itemNumber,
                min: 3
            },
            customer: customerProp,
            text: {
                // Override the default text "Shop this look"
                modalHeading: $(container).data('shopthelookheading'),
                // Override the default text "View details".
                viewDetailsCTA: $(container).data('viewdetailscta'),
                backToLookCTA: $(container).data('backtolookcta'),
                // Override the default text "Shop".
                itemLinkCTA: $(container).data('itemlinkcta')
            },
            navigation: {
                clickItemLinkMobile: 'new-tab'
            },
            display: {
                disableMnM: true,
                hideAnchorItem: true
            }
        });

        widgetInstance.on('mount', 'bundles', function (data) {
            if (data.bundles && data.bundles.length >= 3) {
                $('#stylitics-title-' + styliticsContainerId).removeClass('d-none');
                $('.stylitics-jump-link-container').removeClass('d-none');
                $styliticsContainer.data('itemNumber', getActiveStyle());
            }
        });

        widgetInstance.on('click', 'item', function () {
            if (window.utag_data) {
                var pid = $productDetail.data('masterid');
                $(document).trigger('stylitics:shopdetail', pid);
            }
        });

        widgetInstance.start();

        $('body').on('product.variantselectionchange', function () {
            var containerStyle = $styliticsContainer.data('itemNumber');

            if (containerStyle !== getActiveStyle()) {
                $('#stylitics-title-' + styliticsContainerId).addClass('d-none');
                $('.stylitics-jump-link-container').addClass('d-none');

                widgetInstance.refresh({ api: { item_number: getActiveStyle() } });
                $styliticsContainer.data('itemNumber', getActiveStyle());
            }
        });

        initializeJumpLink();
    }
}

/**
 *  Stylitics Gallery Carousel Component
 *  @param {Element} container - container on which the widget should be initialized
 */
function createStyliticsGalleryCarousel(container) {
    var containerId = $(container).data('component-id');
    var styliticsWidgetContainer = 'stylitics-gallery-carousel-widget-container-' + containerId;
    var locale = $(container).data('locale').replace('_', '-');
    var slugID = $(container).data('slug-id') !== '' ? $(container).data('slug-id') : undefined;

    if ($('#' + styliticsWidgetContainer).is(':empty') && typeof StyliticsClassicWidget !== 'undefined') {
        let widgetInstance = new StyliticsClassicWidget($(container).data('username'), styliticsWidgetContainer, {
            api: {
                tags: slugID,
                min: 3,
                max: 6
            },
            customer: {
                locale: locale
            },
            text: {
                viewDetailsCTA: $(container).data('shopthelookcta'),
                backToLookCTA: $(container).data('backtolookcta'),
                itemLinkCTA: $(container).data('itemlinkcta')
            },
            navigation: {
                clickItemLinkMobile: 'new-tab'
            },
            display: {
                disableMnM: true,
                swipeableCarouselArrows: true,
                cardAspectRatio: 1.5,
                clickableCarouselDots: true,
                swipeableCarouselDots: true,
                bundleBackgroundColor: '#f6f6f6',
                bundleProductList: 'product-list-on-click'
            }
        });

        widgetInstance.start();
        widgetInstance.on('mount', 'bundles', function (data) {
            if (data.bundles && data.bundles.length) {
                // The triiger happens before the custom event is attached to document.
                $(window).on('load', function () {
                    $(document).trigger('tealium:StyliticGalleryCarouselComponentLoaded', '#' + styliticsWidgetContainer);
                });
            }
        });
    }
}

/**
 * Create stylitics PGP single card gallery
 *  @param {Element} container - container on which the widget should be initialized
 */
function createStyliticsGridTile(container) {
    var gridTileContainerId = $(container).data('componentId');
    var $gridTileContainer = $('#stylitics-gallery-widget-grid-tile-container-' + gridTileContainerId);
    var locale = $(container).data('locale').replace('_', '-');
    // DOM Variable passed to widget init
    var slug = $(container).data('gallerySlug');

    if ($gridTileContainer.is(':empty') && typeof StyliticsGalleryWidget !== 'undefined') {
        let widgetInstance = new StyliticsGalleryWidget($(container).data('username'), $gridTileContainer.attr('id'), {
            api: {
                tags: slug,
                min: 1,
                // display only one card
                max: 1
            },
            customer: {
                locale: locale
            },
            display: {
                disableMnM: true,
                bundleProductList: 'product-list-on-click',
                mobileGalleryLayout: true,
                cardAspectRatio: 1.5
            },
            text: {
                // Override the default text "View details".
                viewDetailsCTA: $(container).data('shopthelookcta'),
                backToLookCTA: $(container).data('backtolookcta'),
                // Override the default text "SHOP".
                itemLinkCTA: $(container).data('itemlinkcta')
            }
        });

        widgetInstance.start();
    }
}

/**
 * .Initiliazes full gallery
 *  @param {Element} container - container on which the widget should be initialized
*/
function createFullGallery(container) {
    var containerId = $(container).data('componentId');
    var widgetContainer = 'stylitics-widget-full-gallery-container-' + containerId;
    var slug = $(container).data('gallerySlug');
    var locale = $(container).data('locale').replace('_', '-');

    if ($('#' + widgetContainer).is(':empty') && typeof StyliticsGalleryWidget !== 'undefined') {
        let widgetInstance = new StyliticsGalleryWidget($(container).data('username'), widgetContainer, {
            api: {
                tags: slug,
                min: 3
            },
            display: {
                disableMnM: true,
                bundleProductList: 'product-list-on-click',
                bundleBackgroundColor: '#f6f6f6',
                mobileGalleryLayout: true,
                cardAspectRatio: 1.5
            },
            text: {
                viewDetailsCTA: $(container).data('shopthelookcta'),
                backToLookCTA: $(container).data('backtolookcta'),
                itemLinkCTA: $(container).data('itemlinkcta')
            },
            customer: {
                locale: locale
            },
            responsive: [
                [0, { columns: 2 }],
                [768, { columns: 3 }],
                [992, { columns: 4 }]
            ]
        });
        widgetInstance.start();

        widgetInstance.on('mount', 'bundles', function (data) {
            if (data.bundles && data.bundles.length) {
                // The triiger happens before the custom event is attached to document.
                $(window).on('load', function () {
                    $(document).trigger('tealium:StyliticFullGalleryComponentLoaded', '#' + widgetContainer);
                });
            }
        });
    }
}

/**
 * Initializes Stylitics widgets
 * @param {Array} classArray array of widgets
 */
function initializeStyliticsWidgets(classArray) {
    classArray.forEach(function (obj) {
        var $container = $(obj.container);
        if ($container.length) {
            $container.each(function (index, ele) {
                obj.function(ele);
            });
        }
    });
}

/**
 * Injects script element to <body>
 * Note: This function should only run once per library
 * @param {string} scriptName script class name
 * @param {string} styliticsLibUrl stylitics library url
 * @param {function} callBack this is the create widget function
 */
function createScriptElement(scriptName, styliticsLibUrl, callBack) {
    // create script elment
    var styliticsScript = document.createElement('script');
    styliticsScript.src = styliticsLibUrl;
    styliticsScript.type = 'text/javascript';
    styliticsScript.classList.add(scriptName);
    // add the script to the DOM
    document.body.appendChild(styliticsScript);

    // Attach 'load' event handler to create widgets once script loads
    styliticsScript.addEventListener('load', () => {
        callBack();
    });
}

/**
 * Helper to create Stylitics script dynamically. The `js-url` attribute on the stylitics
 * component in the isml is the script url that is used for initialization. the `lib-type` property
 * helps drive the widget type to initialize with that script url
 */
function initStyliticsJsPathScript() {
    var styliticsComponents = $('[id^=stylitics-container]');
    // load script as long as we have components on the page
    if (styliticsComponents.length > 0) {
        for (let index = 0; index < styliticsComponents.length; index += 1) {
            let component = $(styliticsComponents[index]);

            // Widget can be a gallery, classic, or loader - based on js lib required
            var libType = component.data('libType');

            var styliticsLibUrl = component.data('jsUrl'); // any component could provide a different data-jsUrl
            if (styliticsLibUrl && $('script.stylitics-' + libType + '-script').length <= 0) {
                var scriptName = 'stylitics-' + libType + '-script';
                switch (libType) {
                    case 'loader':
                        createScriptElement(scriptName, styliticsLibUrl, function () {
                            initializeStyliticsWidgets([
                                {
                                    container: shopTheLook,
                                    function: createStyliticsPDPCarousel
                                }
                            ]);
                        });
                        break;
                    case 'classic':
                        createScriptElement(scriptName, styliticsLibUrl, function () {
                            initializeStyliticsWidgets([
                                {
                                    container: '.shop-the-look-gallery-carousel',
                                    function: createStyliticsGalleryCarousel
                                }
                            ]);
                        });
                        break;
                    case 'gallery':
                        createScriptElement(scriptName, styliticsLibUrl, function () {
                            initializeStyliticsWidgets([
                                {
                                    container: '.shop-the-look-grid-tile',
                                    function: createStyliticsGridTile
                                },
                                {
                                    container: '.shop-the-look-gallery',
                                    function: createFullGallery
                                }
                            ]);
                        });
                        break;
                    case 'dynamicLoader':
                        createScriptElement(scriptName, styliticsLibUrl, function () {
                            initializeStyliticsWidgets([
                                {
                                    container: '.shop-the-look-dynamic-galleries',
                                    function: createShopTheLookDynamicGalleries
                                }
                            ]);
                        });
                        break;
                    default:
                        break;
                }
            }
        }
    }
}

/**
 * Add event handlers directly to elements
 */
function addClickEventListeners() {
    // Stop propagation for improved INP
    $('.experience-commerce_assets-ShopTheLook:not([data-listening]').on('click', function (e) {
        e.stopPropagation();
    });

    $('.experience-commerce_assets-ShopTheLook:not([data-listening])').on('click', '.stylitics-dimension-collage-card-footer', function () {
        if (window.utag_data) {
            var pid = $productDetail.data('masterid');
            $(document).trigger('stylitics:viewdetails', pid);
        }
    });

    $('.experience-commerce_assets-ShopTheLook:not([data-listening])').on('click', 'stylitics-desktop-modal .stylitics-card', function () {
        if (window.utag_data) {
            var pid = $productDetail.data('masterid');
            $(document).trigger('stylitics:seemoreoptionsproduct', pid);
        }
    });

    // Prevent listeners from getting added more than once
    $('.experience-commerce_assets-ShopTheLook').attr('data-listening', 'true');

    // This event is used to re-init the grid tile widgets when clicking on search refinements
    $(document).on('search:applyingFilter', function () {
        initializeStyliticsWidgets([
            {
                container: '.shop-the-look-grid-tile',
                function: createStyliticsGridTile
            }
        ]);
    });
}

/**
 * Handles all the individual widget, component, and analytics initializations.
 */
function initializeStylitics() {
    initStyliticsJsPathScript();
    addClickEventListeners();
    var pageData = $('.page') && $('.page').data() ? $('.page').data() : false;
    if (pageData && pageData.action && pageData.action === 'Product-Show') {
        document.addEventListener('content:updated', () => {
            initStyliticsJsPathScript();
            addClickEventListeners();
        });
    }
}

$(window).on('load', function () {
    var $shopTheLook = $(shopTheLook);
    // Track component load event
    if (window.utag_data && $shopTheLook.length > 0 && $('.stylitics-jump-link').is(':visible')) {
        var pid = $productDetail.data('masterid');
        $(document).trigger('stylitics:impression', pid);
    }
});

$(document).ready(function () {
    initializeStylitics();
});
