'use strict';

const { getSearchParams } = require('../util/url');

/**
 * Create speculation rules.
 * @param {string} url - URL.
 */
function createSpeculationRules(url) {
    const specScript = document.createElement('script');
    specScript.type = 'speculationrules';
    var specRules = {
        prerender: [
            {
                source: 'list',
                urls: [url]
            }
        ]
    };
    specScript.textContent = JSON.stringify(specRules, null, 2);
    document.body.append(specScript);
}

/**
 * Handle interaction.
 * @param {Event} event - Event.
 */
function handleInteraction(event) {
    const target = event.target;
    const prerenderElement = target ? target.closest('[data-prerender]') : null;

    if (!prerenderElement) {
        return;
    }

    if (prerenderElement.hasAttribute('data-prerender-handled')) {
        return;
    }

    prerenderElement.setAttribute('data-prerender-handled', 'true');
    const url = prerenderElement.getAttribute('href');

    if (url) {
        createSpeculationRules(url);
    }

    if (window.console) {
        window.console.log('Prerendering: ', url);
    }
}

/**
 * Initialize.
 */
function init() {
    const params = getSearchParams(window.location.href);

    // Exit early when url parameter is missing.
    if (params.prerender !== 'true') {
        return;
    }

    // Exit early when not enabled.
    const enabled = document.body.getAttribute('data-enable-prerendering');

    if (enabled !== 'true') {
        return;
    }

    // Exit early when not supported.
    const speculationRulesSupported = window.HTMLScriptElement
        && HTMLScriptElement.supports
        && HTMLScriptElement.supports('speculationrules');

    if (!speculationRulesSupported) {
        return;
    }

    const watchElements = document.querySelectorAll('[data-prerender], .popover');

    for (let i = 0; i < watchElements.length; i += 1) {
        watchElements[i].addEventListener('mouseover', handleInteraction);
        watchElements[i].addEventListener('touchstart', handleInteraction);
    }
}

/**
 * Public.
 * @type {{init: init}}
 */
module.exports = {
    init
};
