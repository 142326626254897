/* eslint-disable sonarjs/no-duplicate-string */

'use strict';

class AccordionComponent extends HTMLElement {
    static init() {
        if (!window.customElements || window.customElements.get('accordion-component')) {
            return;
        }

        window.customElements.define('accordion-component', AccordionComponent);
    }

    static get observedAttributes() {
        return ['data-title', 'icon', 'class'];
    }

    constructor() {
        super();

        this.attachShadow({ mode: 'open' });

        const template = document.createElement('template');
        template.innerHTML = `
        <style>
            :host {
                display: block;
                font-family: Proxima Nova W01, -apple-system, BlinkMacSystemFont, Helvetica Neue, Helvetica, Arial, sans-serif; 
                -webkit-font-smoothing: antialiased;
            }

            .accordion-header {
                display: flex;
                align-items: center;
                justify-content: space-between;
                width: 100%;
                font-size: var(--nb-font-size-04);
                font-weight: var(--nb-font-weight-03);
                font-family: inherit;
                text-decoration: none;
                background-color: transparent;
                color: var(--nb-color-black);
                position: relative;
                overflow: hidden;
                cursor: pointer;
                border: none;
                padding: var(--nb-spacing-06) var(--nb-spacing-06) var(--nb-spacing-06) 0;
                margin: 0;
            }

            :host-context(accordion-group-component.border) .accordion-header {
                padding-left: var(--nb-spacing-06);
            }
            
            .accordion-content {
                display: grid;
                grid-template-rows: 0fr;
                font-size: var(--nb-font-size-03);
                font-weight: var(--nb-font-weight-03);
                position: relative;
                z-index: -1;
                transition: all 0.35s ease;
            }

            .accordion-content > div {
                overflow: hidden;
            }

            :host(.open) .accordion-content {
                grid-template-rows: 1fr;
                position: relative;
                overflow: hidden;
                z-index: 0;
                transition: all 0.35s ease;
            }
            
            .accordion-icon {
                display: flex;
                align-items: center;
                justify-content: center;
                transform: rotate(0);
                opacity: 0.62;
                transition: 0.35s ease;
            }

            :host([icon='plus-minus']) .accordion-icon {
                display: block;
                position: relative;
                width: 14px;
                height: 2px;
            }

            :host(.open:not([icon='plus-minus'])) .accordion-icon {
                transform: rotate(180deg);
            }

            :host([icon='plus-minus']) ::slotted(svg) {
                position: absolute;
                top: 0;
                left: 0;
                transform: rotate(0deg);
                transition: 0.4s ease;
            }

            :host(:not(.open)[icon='plus-minus']) .accordion-icon ::slotted(svg:first-of-type) {
                transform: rotate(90deg);
            }

        </style>

            <button type='button' class='accordion-header' part='accordion-header'>
                <span class='accordion-title' part='accordion-title'>Accordion Title</span>
                <div class='accordion-icon' part='accordion-icon'>
                    <slot name='icon'></slot>
                </div>
            </button>
            <div class='accordion-content' part='accordion-content'>
                <div>
                    <slot></slot>
                </div>
            </div>

        `;

        this.shadowRoot.appendChild(template.content.cloneNode(true));

        this.accordionHeader = this.shadowRoot.querySelector('.accordion-header');
        this.accordionTitle = this.shadowRoot.querySelector('.accordion-title');
        this.accordionContent = this.shadowRoot.querySelector('.accordion-content');

        this.accordionHeader.addEventListener('click', (e) => {
            e.preventDefault();
            this.toggleAccordion();
        });
    }

    toggleAccordion() {
        this.classList.toggle('open');
        const singleViewModeEnabled = this.parentElement.hasAttribute('single-view');

        // Close all other accordions when one is opened
        const allAccordions = this.parentElement.querySelectorAll('accordion-component');
        allAccordions.forEach(accordion => {
            if (singleViewModeEnabled && accordion !== this) {
                accordion.classList.remove('open');
            }
        });
    }

    renderIcon(iconAttribute) {
        let iconHTML;

        if (iconAttribute === 'plus-minus') {
            iconHTML = `
                <svg slot="icon" width="14" height="2" xmlns="http://www.w3.org/2000/svg">
                    <line style="stroke: black; height: 1.35px; stroke-width: 1.35px;" x1="0" y1="1" x2="14" y2="1"></line>
                </svg>
                <svg slot="icon" width="14" height="2" xmlns="http://www.w3.org/2000/svg">
                    <line style="stroke: black; height: 1.35px; stroke-width: 1.35px;" x1="0" y1="1" x2="14" y2="1"></line>
                </svg>
            `;
        } else {
            iconHTML = `
                <svg
                    slot='icon'
                    role='img'
                    alt='accordion-icon'
                    class='icon svg-icon'
                    width='14'
                    height='14'
                    aria-hidden='true'
                >
                    <title>icon</title>
                    <desc>icon</desc>
                    <use xlink:href='#${iconAttribute}'></use>
                </svg>
            `;
        }

        this.insertAdjacentHTML('afterbegin', iconHTML);
    }

    connectedCallback() {
        const uniqueId = Math.random().toString(16).slice(10);
        this.accordionHeader.setAttribute('aria-controls', 'accordion-' + uniqueId);
        this.accordionContent.setAttribute('id', 'accordion-' + uniqueId);

        // Use the val of [data-title] attribute to render accordion's header text.
        const titleValue = this.getAttribute('data-title');
        if (titleValue) {
            this.accordionTitle.textContent = titleValue;
        }
        const iconAttribute = this.getAttribute('icon');
        if (iconAttribute && !this.querySelector('[slot=icon]')) {
            this.renderIcon(iconAttribute);
        }
    }

    attributeChangedCallback(attr, oldValue, newValue) {
        if (attr === 'data-title') {
            this.accordionTitle.textContent = newValue;
        }
        if (attr === 'icon') {
            this.querySelectorAll('[slot=icon]').forEach(el => el.remove());
            if (newValue !== '' && !this.querySelector('[slot=icon]')) {
                this.renderIcon(newValue);
            }
        }
        if (attr === 'class') {
            if (newValue.includes('open')) {
                this.accordionHeader.setAttribute('aria-expanded', 'true');
            } else {
                this.accordionHeader.setAttribute('aria-expanded', 'false');
            }
        }
    }
}

module.exports = AccordionComponent;
