'use strict';

/**
 * Add mask to all telephone elements for US and CA regions
 * @param {string} formType - specific form to update
 */
function addUsCaMask(formType) {
    var selector = document.querySelectorAll('input[type=tel]');
    if (formType) {
        selector = $(formType).closest('form').find('input[type=tel]');
    }
    if (selector.length) {
        require.ensure(['inputmask'], function (require) {
            var Inputmask = require('inputmask');
            Inputmask({
                mask: '(999) 999-9999',
                onincomplete: function () {
                    var error = $(this).data('pattern-mismatch');
                    this.setCustomValidity(error);
                },
                oncomplete: function () {
                    this.setCustomValidity('');
                }
            }).mask(selector);
        });
    }
}
/**
 * Add mask to all telephone elements for GB/UK regions
 * @param {string} formType - specific form to update
 */
function addGBMask(formType) {
    var selector = document.querySelectorAll('input[type=tel]');
    if (formType) {
        selector = $(formType).closest('form').find('input[type=tel]');
    }
    if (selector.length) {
        require.ensure(['inputmask'], function (require) {
            var Inputmask = require('inputmask');
            Inputmask({
                mask: function () { return ['999 999 9999', '999 9999 9999']; }, // pass multiple formats as an array to handle different data formatting.
                greedy: false,
                onincomplete: function () {
                    var error = $(this).data('pattern-mismatch');
                    this.setCustomValidity(error);
                },
                oncomplete: function () {
                    this.setCustomValidity('');
                }
            }).mask(selector);
        });
    }
}

/**
* Add mask to all telephone elements for HK region
* @param {string} formType - specific form to update
*/
function addHKMask(formType) {
    var selector = document.querySelectorAll('input[type=tel]');
    if (formType) {
        selector = $(formType).closest('form').find('input[type=tel]');
    }
    if (selector.length) {
        require.ensure(['inputmask'], function (require) {
            var Inputmask = require('inputmask');
            Inputmask({
                mask: '9999-9999',
                onincomplete: function () {
                    var error = $(this).data('pattern-mismatch');
                    this.setCustomValidity(error);
                },
                oncomplete: function () {
                    this.setCustomValidity('');
                }
            }).mask(selector);
        });
    }
}

/**
 * Add mask to all telephone elements for TW region
 * @param {string} formType - which form needs the phone mask
 */
function addTwMask(formType) {
    var selector = document.querySelectorAll('input[type=tel]');
    if (formType) {
        selector = $(formType).closest('form').find('input[type=tel]');
    }
    if (selector.length) {
        require.ensure(['inputmask'], function (require) {
            var Inputmask = require('inputmask');
            Inputmask('9{0,10}').mask(selector);
        });
    }
}

/**
 * Add mask to all telephone elements for the rest of the world
 * @param {string} formType - which form needs the phone mask
 */
function addNonUsCaMask(formType) {
    var selector = document.querySelectorAll('input[type=tel]');
    if (formType) {
        selector = $(formType).closest('form').find('input[type=tel]');
    }
    if (selector.length) {
        require.ensure(['inputmask'], function (require) {
            var Inputmask = require('inputmask');
            Inputmask('9{0,20}').mask(selector);
        });
    }
}

/**
 * This will load the initial mask.
 * @param {string} value - Value of the country
 * @param {string} formType - which form needs the phone mask
 */
function loadInitialMask(value, formType) {
    if (formType) {
        formType = '#' + formType + 'Country'; // eslint-disable-line no-param-reassign
    }
    var isCAorUS = ['CA', 'US', ''].indexOf(value) > -1;
    var isGB = ['GB'].indexOf(value) > -1;
    var isTW = ['TW'].indexOf(value) > -1;
    var isAPAC = ['HK', 'SG'].indexOf(value) > -1;

    if (isCAorUS) {
        addUsCaMask(formType);
    } else if (isGB) {
        addGBMask(formType);
    } else if (isTW) {
        addTwMask(formType);
    } else if (isAPAC) {
        addHKMask(formType);
    } else {
        addNonUsCaMask(formType);
    }
}

$(document).ready(function () {
    var selector = '#shippingCountry, #addressCountry';
    loadInitialMask($(selector).val());
    $(document).on('change', '#shippingCountry, #addressCountry', function () {
        var isCAorUS = ['CA', 'US'].indexOf(this.value) > -1;
        var isGB = ['GB'].indexOf(this.value) > -1;
        var isTW = ['TW'].indexOf(this.value) > -1;
        var isAPAC = ['HK', 'SG'].indexOf(this.value) > -1;
        var isJP = ['JP'].indexOf(this.value) > -1;

        if (isCAorUS) {
            addUsCaMask();
        } else if (isGB) {
            addGBMask();
        } else if (isTW) {
            addTwMask();
        } else if (isAPAC) {
            addHKMask();
        } else {
            addNonUsCaMask();
        }
    });
});

window.loadInitialMask = loadInitialMask;
