'use strict';

/**
 * Set focus with small delay.
 * @param {Object} $element - JQuery object.
 * @param {number} timeout - Timeout.
 */
function setFocus($element, timeout = 5) {
    setTimeout(() => {
        $element.focus();
    }, timeout);
}

module.exports = setFocus;
