'use strict';

const SECONDS = 1000;
const MINUTES = 60 * SECONDS;
const HOURS = 60 * MINUTES;
const DAYS = 24 * HOURS;

class Countdown extends HTMLElement {
    static init() {
        if (!window.customElements || window.customElements.get('count-down')) {
            return;
        }

        window.customElements.define('count-down', Countdown);
    }

    static get observedAttributes() {
        return ['to', 'description'];
    }

    constructor() {
        super();

        this.isInitialized = false;

        this.miscElements = {};
        this.countdownElements = {};

        this.to = null;
        this.updater = this.update.bind(this);
        this.updaterInterval = null;
    }

    // Lifecycle methods called by browser.

    attributeChangedCallback(name, oldValue, newValue) {
        if (!this.isInitialized) {
            return;
        }

        switch (name) {
            case 'description':
                this.updateDescription(newValue);
                break;
            case 'to':
                this.finish();
                this.init();
                break;
            default:
                break;
        }
    }

    connectedCallback() {
        // Cache DOM lookups.
        this.countdownElements = Array.from(this.querySelectorAll('[data-time-unit]'))
            .reduce((result, item) => {
                const unit = item.getAttribute('data-time-unit');
                return Object.assign({}, result, {
                    [unit]: item
                });
            }, {});

        this.miscElements = {
            countdownContainer: this.querySelector('.timer-countdown'),
            textContainer: this.querySelector('.timer-description'),
            text: this.querySelector('.timer-description-text')
        };

        // Validate DOM lookups.
        if (!this.isComplete(['days', 'hours', 'minutes', 'seconds'], this.countdownElements)
            || !this.isComplete(['countdownContainer', 'textContainer', 'text'], this.miscElements)) {
            return;
        }

        // Initialize.
        this.init();
    }

    disconnectedCallback() {
        if (!this.isInitialized) {
            return;
        }

        this.finish();
    }

    // Init and finish.

    init() {
        this.isInitialized = true;

        const { text, textContainer, countdownContainer } = this.miscElements;
        const timestamp = this.getAttribute('to');
        const date = timestamp ? new Date(timestamp) : null;

        // When there is no valid countdown date, hide countdown.
        if (!date) {
            this.finish();
            this.setElementDisplay(this, false);
            return;
        }

        this.setElementDisplay(this, true);
        this.setElementDisplay(textContainer, text.textContent.trim() !== ''); // Only show text when there is a description.
        this.setElementDisplay(countdownContainer, true); // Show countdown container.

        this.to = date;
        this.updaterInterval = setInterval(this.updater, 1000);
        this.update();
    }

    finish() {
        clearInterval(this.updaterInterval);
        this.updateUI({
            days: 0, hours: 0, minutes: 0, seconds: 0
        });
        this.setElementDisplay(this, false); // Hide.
    }

    // Description.

    updateDescription(value) {
        const { text, textContainer } = this.miscElements;
        const html = value || '';

        text.innerHTML = html;
        this.setElementDisplay(textContainer, Boolean(html));
    }

    // Timer methods.

    update() {
        const now = Date.now();
        const distance = this.to.getTime() - now;

        if (distance <= 0) {
            this.finish(); // Clear interval.
            this.dispatchFinishedEvent(); // Dispatch event.

            return;
        }

        // Time calculations for days, hours, minutes and seconds
        const days = Math.floor(distance / DAYS);
        const hours = Math.floor((distance % DAYS) / HOURS);
        const minutes = Math.floor((distance % HOURS) / MINUTES);
        const seconds = Math.floor((distance % MINUTES) / SECONDS);

        // Update UI.
        this.updateUI({
            days, hours, minutes, seconds
        });
    }

    updateUI(values) {
        const {
            days, hours, minutes, seconds
        } = values;
        const elements = this.countdownElements;
        const formatted = {
            days: days > 99 ? String(days) : ('0' + days).slice(-2),
            hours: ('0' + hours).slice(-2),
            minutes: ('0' + minutes).slice(-2),
            seconds: ('0' + seconds).slice(-2)
        };

        if (elements.days.textContent !== formatted.days) {
            elements.days.textContent = formatted.days;
        }

        if (elements.hours.textContent !== formatted.hours) {
            elements.hours.textContent = formatted.hours;
        }

        if (elements.minutes.textContent !== formatted.minutes) {
            elements.minutes.textContent = formatted.minutes;
        }

        elements.seconds.textContent = formatted.seconds;
    }

    // Misc.

    dispatchFinishedEvent() {
        this.dispatchEvent(new CustomEvent('countdown:finished', {
            bubbles: true
        }));
    }

    // eslint-disable-next-line class-methods-use-this
    isComplete(keys, elements) {
        for (let i = 0; i < keys.length; i += 1) {
            const key = keys[i];
            if (!elements[key]) {
                if (window.console) {
                    window.console.warn(`Countdown: missing ${key} element.`);
                }
                return false;
            }
        }

        return true;
    }

    // eslint-disable-next-line class-methods-use-this
    setElementDisplay(element, show) {
        if (element) {
            element.classList[show ? 'remove' : 'add']('d-none');
        }
    }
}

module.exports = Countdown;
