'use strict';

const BREAKPOINTS = {
    mobile: {
        query: '(max-width:992px)',
        index: 1
    },
    smallDesktop: {
        query: '(min-width:992px) and (max-width: 1200px)',
        index: 2
    },
    largeDesktop: {
        query: '(min-width: 1200px)',
        index: 3
    }
};

/**
 * Check if we are on a mobile breakpoint.
 * @returns {boolean} - True or false.
 */
function isMobile() {
    return window.matchMedia(BREAKPOINTS.mobile.query).matches;
}

/**
 * Check if we are on a small desktop breakpoint.
 * @returns {boolean} - True or false.
 */
function isSmallDesktop() {
    return window.matchMedia(BREAKPOINTS.smallDesktop.query).matches;
}

/**
 * Check if we are on a large desktop breakpoint.
 * @returns {boolean} - True or false.
 */
function isLargeDesktop() {
    return window.matchMedia(BREAKPOINTS.largeDesktop.query).matches;
}

/**
 * Checking if the element is in viewport.
 * @param {HTMLElement} element - The DOM Element to evaluate.
 * @param {number} toleratedMargin - Tolerated margin.
 * @returns {boolean} - If object is in view.
 */
function isInViewport(element, toleratedMargin = 0) {
    if (!element) {
        return false;
    }

    const viewPortMin = 0 - toleratedMargin;
    const viewPortMax = window.innerHeight + toleratedMargin;
    const elementRect = element.getBoundingClientRect();
    const elementMin = elementRect.top;
    const elementMax = elementRect.top + elementRect.height;

    if (elementMin > viewPortMin && elementMin < viewPortMax) {
        return true;
    }

    return elementMax > viewPortMin && elementMax < viewPortMax;
}

module.exports = {
    isMobile,
    isSmallDesktop,
    isLargeDesktop,
    isInViewport
};
